import React from "react";
import { FormattedMessage } from "react-intl";
import "./videoresume.scss";
import { uploadFile } from "../../../utils/fileService";
import * as userActions from "redux/actions/UserActions";
import { useDispatch } from "react-redux";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";

export const PreviewScreen = (props) => {
    const dispatch = useDispatch();
    const userId = storage.get("humlog_user");
    const history = useHistory();
    // method for retake
    const retake = () => {
        window.location.reload();
    }
    //  method to upload video resume
    const onSubmit = async() => {
        const videoUrl = await uploadFile(props.file, "video");
        let formData = {
          video_resume: videoUrl,
          _id: localStorage.getItem("humlog_user"),
        };
        dispatch(userActions.updateUserData(formData))
          .then((res) => {
            if (res.value.success) {
              dispatch(userActions.getUserData(userId));
              history.push('/profile');
              console.log(res.value.message);
            }
          })
          .catch((err) => console.log(err));
    }
 return (
  <div className="main_user st p_bottom">
    <div className="container-fluid preview_container">
    <div className="text-center">
      <h5>
        <FormattedMessage
          id="resumePreview"
          defaultMessage="Resume Preview"
        />
      </h5>
    </div>
    <div className="mt-4 video_recorder">
        <div className=" recorder_inner">
            <video
            src={props.src}
            controls
            className="video_player"
            id="videoPlayer"
            />
        </div>
    </div>
    <div className="video_controls">
        <div className="controls_inner">
            <button className="control_btn retake_btn" id="retakeBtn" onClick={retake} >
            <FormattedMessage
                  id="back"
                  defaultMessage="Back"
                />
            </button>
            <button className="control_btn submit_btn" id="submitBtn" onClick={onSubmit}>
            <FormattedMessage
                  id="submit"
                  defaultMessage="Submit"
                />
            </button>
        </div>
    </div>
</div>
</div>
 )
}