import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import "../userprofie/userprofile.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import storage from "utils/storage";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";
import appConstant from "config/app.constant";
export const ExpectationSalary = ({ handelClose }) => {
    const dispatch = useDispatch();
    const { userDetails } = useSelector((state) => state.userReducer);
    const [error, setError] = useState({ open: false, message: '' });
    const translate = useIntl();
    const { register, handleSubmit, reset, errors } = useForm({
        mode: "onBlur",
    });
    useEffect(() => {
        loadProfile();
    }, []);
    const loadProfile = () => {
        reset(userDetails); // resetting the userDetails 
    }; 

    // API to update user profile data
    const update = (val) => {
        let data = { ...val, _id: storage.get("humlog_user") };
        dispatch(userActions.updateUserData(data))
            .then((res) => {
                if (res.value.success) {
                    dispatch(userActions.getUserData(storage.get("humlog_user")));
                    reset();
                    handelClose();
                }
            })
            .catch((err) => {
                setError({ open: true, message: err.data.message })
            });
    };


    return (
        <>
            <Form.Group controlId="salary">
                {/* salary-from section start from here */}
                <Form.Label className="d-flex justify-content-start llbl">
                    <FormattedMessage id="minimumSalaryValue" />
                </Form.Label>
                <Form onSubmit={handleSubmit(update)}>
                    <Form.Control
                        type="number"
                        placeholder={appConstant.SALARY_FROM_TO[0]}
                        name="salary_from"
                        autocomplete="off"
                        className={classNames("form-control", {
                            "is-invalid": errors.salary_from,
                        })}
                        ref={register({
                            minLength: {
                                value: 4,
                                message: translate.formatMessage({ id: "minimumFourDigitAllowed" }),
                            },
                            maxLength: {
                                value: 5,
                                message: translate.formatMessage({ id: "maximumFiveDigit" }),
                            },
                            pattern: {
                                value: regexValidation.SALARY_EXPECTATION,
                                message: translate.formatMessage({ id: "enterOnlyNumbers" }),
                            },
                            validate: {
                                validation: (value) =>
                                    (value >= appConstant.SALARY.MINIMUM && value <= appConstant.SALARY.MAXIMUM) ||
                                    translate.formatMessage({ id: "minimumSalary" }),
                            },
                        })}
                    />
                    {errors.salary_from && (
                        <p className="text-danger p-0 m-0">
                            {errors.salary_from.message}
                        </p>
                    )}
                    {/* salary-from section end */}
                    {/* salary-to section start from here */}
                    <Form.Label className="d-flex justify-content-start llbl mt-3">
                        <FormattedMessage id="maximumSalaryValue" />
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder={appConstant.SALARY_FROM_TO[1]}
                        name="salary_to"
                        autocomplete="off"
                        className={classNames("form-control", {
                            "is-invalid": errors.salary_to,
                        })}
                        ref={register({
                            pattern: {
                                value: regexValidation.SALARY_EXPECTATION,
                                message: translate.formatMessage({ id: "enterOnlyNumbers" }),
                            },
                            minLength: {
                                value: 4,
                                message: translate.formatMessage({ id: "minimumFourDigitAllowed" }),
                            },
                            maxLength: {
                                value: 5,
                                message: translate.formatMessage({ id: "maximumFiveDigit" }),
                            },
                            validate: {
                                validation: (value) =>
                                    (value >= appConstant.SALARY.MINIMUM && value <= appConstant.SALARY.MAXIMUM) ||
                                    translate.formatMessage({ id: "salaryMinimum" }),
                            },
                        })}
                    />
                    {errors.salary_to && (
                        <p className="text-danger p-0 m-0">
                            {errors.salary_to.message}
                        </p>
                    )}
                    {/* salary-to section start end */}
                    {/* save-button-section start from here */}
                    <button className="common_btn mt-4" type="submit" id="submit">
                        <FormattedMessage id="save" />
                    </button>
                    {/* save-button-section end */}
                </Form>
            </Form.Group>
        </>
    );
};