import "../../../style/common/userprofile.scss";
import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera, FaRegPlayCircle, FaInfoCircle } from "react-icons/fa";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import * as userActions from "redux/actions/UserActions";
import useri from "../../../assets/images/useri.png";
import { FiVideo} from "react-icons/fi";
import avatar from "../../../assets/images/male_avatar.png";
import { BsBriefcase, BsPencil, BsPlusCircle } from "react-icons/bs";
import { BiCertification, BiTimeFive } from "react-icons/bi";
import { Certificationform } from "components/formmodal/certification/certification";
import { SkillsForm } from "components/formmodal/skills/skillsForm";
import CommonModal from "components/shared/ui-components/common-modal";
import { Userprofileinput } from "components/formmodal/userprofie/Userprofileinput";
import { Industryinterested } from "components/formmodal/industry/Industryinteresred";
import { Aboutself } from "components/formmodal/aboutself/Aboutself";
import { Educatiofrm } from "components/formmodal/education/Educationfrm"
import * as authActions from "redux/actions/AuthActions";
import booki from "../../../assets/images/booki.png";
import genderi from "../../../assets/images/genderi.png";
import jobi from "../../../assets/images/jobsi.png";
import calli from "../../../assets/images/calli.png";
import birthi from "../../../assets/images/birthi.png";
import locationi from "../../../assets/images/locationi.png";
import uploadi from "../../../assets/images/uploadi.png";
import pencili from "../../../assets/images/pencili.png";
import language from "../../../assets/images/language.png";
import { uploadFile } from "../../../utils/fileService";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "components/shared/videoplayer/player";
import { Info } from "components/jobseeker/infomodal/Info"
import { VideoProfile } from "components/jobseeker/videoProfile/videoProfile"
import { buildResume } from "./../GetResume/getresume"
import * as html2pdf from 'html2pdf.js';
import * as commonService from "utils/CommonService";
import ReactTooltip from 'react-tooltip';
import { useIntl } from "react-intl";
import Alert from "@material-ui/lab/Alert";
import salaryImage from "../../../assets/images/salary.png"; // salary Icon
import { ExpectationSalary } from "components/formmodal/salaryExpectationModal/salaryExpectation"; // import salary expectation modal
import appConstant from "config/app.constant";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    background: `linear-gradient(
      90deg,
      rgba(11, 159, 225, 1) 100%,
      rgba(6, 120, 193, 1) 100%
    )`
  },
}))(LinearProgress);
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

export const UserprofileEdit = (props) => {
  /**************** method to download resume as pdf *******************/
  const getresume = async () => {
    const RESUME_HTML = await buildResume(); //fetch resume html as string
    commonService.isLoading.onNext(true); // spinner starts
    let opt = {
      margin: [0, 0, 0, 0],
      filename: `${userDetails.name}_humlogjobs_resume.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, dpi: 72, letterRendering: true, width: 800, height: 1123 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', }
    };
    // convert html to pdf and download
    html2pdf().set(opt).from(RESUME_HTML, "string").save().then(() => {
      commonService.isLoading.onNext(false); // spinner stops
      downloadedResumeCounts() // Storing downloaded resume Counts
    });
  }
  const { userDetails } = useSelector((state) => state.userReducer);
  let userId = storage.get("humlog_user");
  const history = useHistory();
  const translate = useIntl();
  const [picture, setPicture] = useState(avatar);
  const [vedioErr, setVedioErr] = useState(null);
  const [profilePercentage, setProfilePercentage] = useState(0);
  const [modal, setModal] = useState(false);
  const [isError, setisError] = useState({open: false, message: ''}); // storing errors for upload images failed
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getUserData(userId));
    _updateProfileCompleteStatus(profilePercentage); // API to update profile complete value
  }, [dispatch, profilePercentage]);

   // updating profile completed value
   const _updateProfileCompleteStatus = (profilePercentage) => {
    try{
      let formData = {
        profile_completed_status: profilePercentage,
        _id: localStorage.getItem("humlog_user")
      };
      dispatch(userActions.updateUserData(formData))
    } catch(e) {
        console.log("An error occured while updating profile completed status", e);
    }
  }

  const uploadVedioResume = async (e) => {
    if (e.target.files[0].size < 104857600) {
      try {
        const imageUrl = await uploadFile(e.target.files[0], "video");
        setVedioErr(null);
        let formData = {
          video_resume: imageUrl,
          _id: localStorage.getItem("humlog_user"),
        };
        dispatch(userActions.updateUserData(formData))
          .then((res) => {
            if (res.value.success) {
              dispatch(userActions.getUserData(userId));
              console.log(res.value.message);
            }
          })
          .catch((err) => console.log(err));
      } catch (e) {
        console.log("failed to uplaod video", e);
      }
    } else {
      setVedioErr("File size should be less than 100 MB");
    }
  };
  // Skills Placeholder
  function availableSkills(jobIntrested) {
    let result = false;
    if (jobIntrested.length > 0) {
      for(let val of jobIntrested){
          if (val.skills.length) {
            result = true;
            break
          }
      }
    }
    return result;
  }
  // Storing Total Downloaded resume Counts
  const downloadedResumeCounts = () => {
    let formData = {
      total_downloaded_resume:true,
      _id: localStorage.getItem("humlog_user"),
    };
    dispatch(userActions.updateUserData(formData))
      .then((res) => {
        if (res.value.success) {
          dispatch(userActions.getUserData(userId));
          console.log(res.value.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const onChangePicture = async (e) => {
    try {
      // check image upload content format
      let image = e.target.files[0];
      if(!commonService.validateFileContentTypeAndSize(image.type, 'profile')){
        setisError({open: true, message : 'Please upload the correct format file.'});
        setTimeout( () => {setisError({open: false, message : 'Please upload the correct format file.'})}, 5000);
        return;
      }
      // check image upload size 5 MB
      if(!commonService.validateFileSize(image.size, 5)){
        setisError({open: true, message : 'File size should be less than 5 MB'});
        setTimeout( () => {setisError({open: false, message : ''})}, 5000);
        return;
      }
      const imageUrl = await uploadFile(e.target.files[0], "profile");
      if (picture && picture.length > 0) {
        let formData = {
          profile_pic: imageUrl,
          _id: localStorage.getItem("humlog_user"),
        };

        await dispatch(userActions.updateUserData(formData))
          .then((res) => {
            if (res.value.success) {
              dispatch(userActions.getUserData(userId));
              console.log(res.value.message);
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (e) {
      console.log("failed to upload image", e);
    }
  };

  let defaultForm = () => <div></div>;
  const [state, setState] = useState({ open: false });
  const [form, setForm] = useState({ defaultForm });

  const handelModal = (component) => {
    setState({ open: true });
    setForm(component);
  };

  const handelClose = () => {
    setState({ open: false });
  };

  const [choice, setchoice] = useState("");
  const [pData, setPdata] = useState("");

  const handelLogout = () => {
    dispatch(authActions.logout);
    setTimeout(() => {
      window.location.reload();
      history.push("/JobSeekerlogin");
    }, 200);
  };
  const onToggleModal = () => {
    setModal(!modal);
  };
  /**
   * method to set profile Progress
   */
  const setDataProgress = () => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      let profileTracker = 0;
      let skills = [];
      if (userDetails.job_intrested && userDetails.job_intrested.length) {
        for (let val of userDetails.job_intrested) {
          for (let skill of val.skills) {
            skills.push(skill);
          }
        }
      }
      profileTracker = userDetails.about_me?.length > 0 ? profileTracker + 10 : profileTracker + 0;
      profileTracker = userDetails.contact?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.language_known?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.experience >= 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.name?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.dob?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.speak_english?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.address_current?.length > 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.profile_pic?.length > 0 ? profileTracker + 10 : profileTracker + 0;
      profileTracker = userDetails.job_intrested?.length > 0 ? profileTracker + 10 : profileTracker + 0;
      profileTracker = skills?.length > 0 ? profileTracker + 10 : profileTracker + 0;
      profileTracker = userDetails.gender >= 0 ? profileTracker + 5 : profileTracker + 0;
      profileTracker = userDetails.qualification?.length > 0 ? profileTracker + 10 : profileTracker + 0;
      profileTracker = userDetails.technical_cert?.length > 0 ? profileTracker + 10 : profileTracker + 0;

      setProfilePercentage(profileTracker);
    }
  };

  useEffect(() => {
    setDataProgress();
  }, [userDetails]);

  return (
    <>
      <CommonModal open={state.open} handelClose={handelClose}>
        {form}
      </CommonModal>
      {/* Showing error for image upload failed */}
      {isError.open && <Alert severity="error" className="alert-toast">{isError.message}</Alert>}
      <div className="main_user st">
        <h5 className="page_title">
          <FormattedMessage id="myProfile" defaultMessage="My Profile" />
        </h5>
        <div className="row p-0 m-0 no-gutters">
          <div className="col-12">

            <div className="d-flex justify-content-center ">
              <div>
                <div className="img_style_prev main_pic_box imgcls">
                  {
                    userDetails.profile_pic &&
                    userDetails.profile_pic.length > 0 ? (
                    <Image picture={`${userDetails.profile_pic}?${Date.now()}`} alt="Profile Picture"></Image>
                  ) : (
                    <Image picture={avatar} alt="Profile Picture"></Image>
                  )
                  }
                  <input
                    type="file"
                    className="d-none"
                    id="cam"
                    onChange={onChangePicture}
                    onClick={(event)=> { 
                      event.target.value = null
                 }}
                    accept=".png, .jpg, .jpeg"
                  />
                  <label htmlFor="cam">
                    <FaCamera className="camera_position" />
                  </label>
                </div>
              </div>
              {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE*/}
              {/* <FiVideo
                size={26}
                className="videoIcon"
                onClick={() =>
                  handelModal(
                    <VideoProfile section="video" handelClose={handelClose} />
                  )
                }
              /> */}
            </div>

            <div className="mt-1 text-capitalize d-flex justify-content-center font-weight-bold">
              {
                userDetails && userDetails.name && userDetails.name.length > 0
                ? 
                userDetails.name
                : 
                ("")
              }
              <sup></sup>
            </div>

            <div className="mt-1 text-capitalize d-flex justify-content-center">
              <p>
                {
                  userDetails &&
                  userDetails.contact &&
                  userDetails.contact.length > 0
                  ? 
                  userDetails.contact
                  : 
                  ("")
                } | {
                  userDetails &&
                  userDetails.address_home &&
                  userDetails.address_home.length > 0
                  ? 
                  userDetails.address_home
                  : 
                  ("")
                }
              </p>
            </div>
            <div className="getreume_mainbox">
              <div className="getreume_box">
                <button
                  type="button"
                  className="resume_btn"
                  onClick={getresume}
                  disabled={profilePercentage && profilePercentage === 100 ? false : true}>
                  <FormattedMessage id="getResume"/>
                </button>
                <FaInfoCircle
                  className="info_icon"
                  data-tip={translate.formatMessage({id : "getInfo"})}
                  onClick={() =>
                    handelModal(
                      <Info handelClose={handelClose} />
                    )}
                />
              </div>
            </div>
            <ReactTooltip  globalEventOff='click'/>

          </div>
        </div>

        <div className="container">

          {/* start progress bar codes */}

          <div className="row mt-2 m-0 no-gutters row_padding_bottom">
            <div className="col-12">
              <h6 className="text-left mb-2">
                {Math.round(profilePercentage)}%{" "}
                <FormattedMessage id="profileCompleted" defaultMessage="Profile completed" />
              </h6>
              <BorderLinearProgress variant="determinate" value={profilePercentage} />
            </div>
          </div>

          {/* end progress bar codes */}

          {/* start about section */}

          <div className="about_me_box">
            <div className="row">
              <div className="col-12 field_pointer" 
                      onClick={() =>
                        handelModal(<Aboutself handelClose={handelClose} />)
                      }
                    >
                <div className="profile_field_box">
                  <div className="label_box">
                    <h6 className="user_one">
                      <img src={useri} className="user_icon" width="16px" height="16px" />
                      <FormattedMessage id="aboutMe" defaultMessage="About me" />
                    </h6>
                    <BsPencil
                      className="pencil"/>
                  </div>
                </div>
                <div>
                  <p className="">
                    {userDetails &&
                      userDetails.about_me &&
                      userDetails.about_me.length > 0 ? (
                      <FormattedMessage id="iam" defaultMessage="I am.." />
                    ) : (
                      <p className="field_placeholder">
                      <FormattedMessage id="aboutYourselfPlaceholder" defaultMessage="Write something about yourself"/>
                    </p>
                    )}
                    {
                      userDetails &&
                      userDetails.about_me &&
                      userDetails.about_me.length > 0
                      ? (userDetails.about_me.map((data, index) => {
                        return (
                          <span>
                            <FormattedMessage id={(data.label)} />
                            {index < userDetails.about_me.length-1 ? ", " : ""}
                          </span>
                          )
                        }))
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* end about section */}

          {/* start job categeory page */}

          <div className="row">
            <div className="col-12 field_pointer" 
                        onClick={() =>
                          handelModal(
                            <Industryinterested handelClose={handelClose} />
                          )
                        }
                      >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={jobi} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="jobInterest" defaultMessage="Job Interested In" />
                  </h6>
                  {userDetails &&
                    userDetails.job_intrested &&
                    userDetails.job_intrested.length < 3 && (
                      <BsPencil
                        className="pencil"/>
                    )}
                </div>
              </div>
              <div className="ml-4 pb-2">
                {userDetails &&
                  userDetails.job_intrested &&
                  userDetails.job_intrested.length > 0 &&
                  userDetails.job_intrested.map((val) => {
                    return (
                      <>
                        <div className="user_btna mr-2" style={{ display: "inline-block" }}>{val.job_category}</div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* end job categeory */}

          {/* start experience */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BsBriefcase className="user_icon" />
                    <FormattedMessage id="yearsOfExp" defaultMessage="Years of experience"
                    />
                  </h6>
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {userDetails && userDetails.experience === 0
                  ? "Fresher"
                  : userDetails && userDetails.experience === 1
                  ? "Less than 1 Year"
                  : userDetails && userDetails.experience === 2
                  ? "1-2 years"
                  : userDetails && userDetails.experience === 3
                  ? "2-4 years"
                  : userDetails && userDetails.experience === 4
                  ? "4-6 years"
                  : userDetails && userDetails.experience === 5
                  ? "6-8 years"
                  : userDetails && userDetails.experience === 6
                  ? "8-10 years"
                  : userDetails && userDetails.experience === 7
                  ? "10+ years"
                  : ""}
                </p>
              </div>
            </div>
          </div>

          {/* end experience */}

          {/* start skills codes */}

          <div className="row">
            <div className="col-12 field_pointer"
              onClick={() =>
                handelModal(<SkillsForm handelClose={handelClose} />)
              }>
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={pencili} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="skills" defaultMessage="Skills" />
                  </h6>
                  <BsPencil
                    className=" pencil"
                  />
                </div>
              </div>
              <div className="ml-4 pb-2">
                <div>
                  
                {userDetails &&
                  userDetails.job_intrested &&
                  userDetails.job_intrested.length > 0 ? (
                    availableSkills(userDetails.job_intrested) ? (
                      userDetails.job_intrested.map((val) => {
                        return (
                          <>
                            <div className="">
                              {val.skills.map((skill) => {
                                return (
                                  <>
                                    <div
                                      className="user_btna mr-2"
                                      style={{ display: "inline-block" }}
                                    >
                                      {skill}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p className="field_placeholder">
                        <FormattedMessage
                          id="submitSkills"
                          defaultMessage="Please select your Skills"
                        />
                      </p>
                    )
                  ) : (
                    <p className="field_placeholder">
                      <FormattedMessage
                        id="submitContact"
                        defaultMessage="Please submit Your Contact"
                      />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* end skills codes */}

          {/* education codes start */}

          <div className="row">
            <div className="col-12 field_pointer" 
                      onClick={() =>
                        handelModal(
                          <Educatiofrm handelClose={handelClose} />
                        )
                      }
                    >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={booki} width="16px" height="20px" className="user_icon" />
                    <FormattedMessage id="educationQualification" defaultMessage="Education & Qualification" />
                  </h6>
                  {
                    (
                    userDetails &&
                    userDetails.qualification &&
                    (userDetails.qualification === 'Other' || parseInt(userDetails.qualification) === 6)
                    ) ? (
                    <BsPencil
                      className=" pencil"/>
                    ) : (" ")
                  }
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {userDetails && parseInt(userDetails.qualification) === 0
                  ? "below 10"
                  : userDetails && parseInt(userDetails.qualification) === 1
                  ? "10 th pass"
                  : userDetails && parseInt(userDetails.qualification) === 2
                  ? "12 th pass"
                  : userDetails && parseInt(userDetails.qualification) === 3
                  ? "B.A"
                  : userDetails && parseInt(userDetails.qualification) === 4
                  ? "B.Sc"
                  : userDetails && parseInt(userDetails.qualification) === 5
                  ? "B.Com"
                  : userDetails && parseInt(userDetails.qualification) === 6
                  ? "Other"
                  : userDetails.qualification}
                </p>
              </div>
            </div>
          </div>

          {/* end  education codes  */}

          {/* Technical Certification codes start */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BiCertification className="user_icon" />
                    <FormattedMessage id="technicalCertification" defaultMessage="Technical Certification" />
                  </h6>
                  {/* Technical Certification Add Icon*/}
                  {(
                    userDetails &&
                    userDetails.technical_cert
                  ) ? (
                    <BsPlusCircle
                      className=" pencil"
                      onClick={() =>
                        handelModal(
                          <Certificationform technical_cert={userDetails.technical_cert}  addtechcert={true} handelClose={handelClose} />
                        )
                      }
                    />
                  ) : (" ")}
                </div>
              </div>
              <div className="tech_certificate_box">
                {userDetails && userDetails.technical_cert
                ?
                userDetails.technical_cert.map((value, index) => {
                  return (
                    <p className="user_tt">
                      <span className="tech_certificate_field">{value.cert} {value.branch ? <>({value.branch})</> : <></>}</span>
                      {/* Technical Certification Edit Icon*/}
                      {!value.branch ? (
                        <BsPencil 
                          className=" pencil"
                          onClick={() =>
                            handelModal(
                              <Certificationform technical_cert={userDetails.technical_cert}  index={index} handelClose={handelClose} />
                            )
                          }
                        />
                      ) : ("") }
                    </p>
                  );
                })
                :
                ("")
                }
              </div>
            </div>
          </div>

          {/* Technical Certification codes end */}

          {/* mobile section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={calli} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="mobileNumber" defaultMessage="Mobile number" />
                  </h6>
                  {!(
                    userDetails &&
                    userDetails.contact &&
                    userDetails.contact.length > 0
                  ) ? (
                    <BsPencil
                      className="user_icon pencil"
                      onClick={() =>
                        handelModal(
                          <Userprofileinput
                            section="mobile"
                            handelClose={handelClose}
                          />
                        )
                      }
                    />
                  ) : ("")}
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {
                    (userDetails &&
                      userDetails.contact &&
                      userDetails.contact.length) > 0
                      ? userDetails.contact
                      : <p className="field_placeholder">
                          <FormattedMessage id="submitContact" defaultMessage="Please submit Your Contact" />
                        </p>
                  }
                </p>
              </div>
            </div>
          </div>

          {/* gender section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={genderi} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="gender" defaultMessage="Gender" />
                  </h6>
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {userDetails && userDetails.gender === 0 ? (
                    <FormattedMessage id="female" defaultMessage="Female" />
                  ) : userDetails && userDetails.gender === 1 ? (
                    <FormattedMessage id="male" defaultMessage="Male" />
                  ) : userDetails && userDetails.gender === 2 ? (
                    <FormattedMessage id="others" defaultMessage="Others" />
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* DOB section starts */}

          <div className="row">
            <div className="col-12 field_pointer"
                    onClick={() =>
                      handelModal(
                        <Userprofileinput
                          section="dob"
                          handelClose={handelClose}
                        />
                      )
                    }
                  >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={birthi} className="user_icon" />
                    <FormattedMessage id="dob" defaultMessage="Date of Birth" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil"/>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetails && userDetails.dob && userDetails.dob.length) > 0
                    ? new Date(userDetails.dob).toLocaleDateString()
                    : (
                      <p className="field_placeholder">
                          <FormattedMessage id="submitDob" defaultMessage="Select Your Date of Birth"/>
                      </p>
                    )
                }
              </p>
            </div>
          </div>

          {/* Language known section starts */}

          <div className="row">
            <div className="col-12 field_pointer"
                    onClick={() =>
                      handelModal(
                        <Userprofileinput
                          section="language"
                          handelClose={handelClose}
                        />
                      )
                    }
                  >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={language} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="languageIKnow" defaultMessage="Languages I Know" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil"/>
                </div>
              </div>
              <div className="ml-4 pb-2">
                {
                  userDetails &&
                  userDetails.language_known &&
                  userDetails.language_known.length > 0?
                  userDetails.language_known.map((val) => {
                    return (
                      <>
                        <div className="user_btna mr-2" style={{ display: "inline-block" }}>
                          <FormattedMessage id={val.toLowerCase().trim()} />
                        </div>
                      </>
                    );
                  }):(<p className="field_placeholder">
                         <FormattedMessage id="submitLanguages" defaultMessage="Select Languages You know"/>
                      </p>)
                }
              </div>
            </div>
          </div>

          {/* Speak English section starts */}

          <div className="row">
            <div className="col-12 field_pointer"
                    onClick={() =>
                      handelModal(
                        <Userprofileinput
                          section="english"
                          handelClose={handelClose}
                        />
                      )
                    }
                  >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={language} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="speakEnglish" defaultMessage="Speak English" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil"/>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetails &&
                    userDetails.speak_english &&
                    userDetails.speak_english.length) > 0
                    ? userDetails.speak_english
                    :(<p className="field_placeholder">
                         <FormattedMessage id="submitEnglishFluency" defaultMessage="Select Your English fluency"/>
                      </p>)
                }
              </p>
            </div>
          </div>

          {/* current district section starts */}

          <div className="row">
            <div className="col-12 field_pointer"
                    onClick={() =>
                      handelModal(
                        <Userprofileinput
                          section="currentAddress"
                          handelClose={handelClose}
                        />
                      )
                    }
                  >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={locationi} className=" user_icon" width="16px" height="16px" />
                    <FormattedMessage id="currentDist" defaultMessage="Current District" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil"/>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetails &&
                    userDetails.address_current &&
                    userDetails.address_current.length) > 0
                    ? userDetails.address_current
                    : (<p className="field_placeholder">
                          <FormattedMessage id="submitDistrict" defaultMessage="Select Your Current District"/>
                       </p>)
                }
              </p>
            </div>
          </div>

          {/* home district section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={locationi} className=" user_icon" width="16px" height="16px" />
                    <FormattedMessage id="homeDistrict" defaultMessage="Home District" />
                  </h6>
                </div>
                <div>
                  <p className="user_tt">
                    {
                      (userDetails &&
                        userDetails.address_home &&
                        userDetails.address_home.length) > 0
                        ? userDetails.address_home
                        : null
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* salary-expectation-range section start from here */}
          <div className="row">
            <div className="col-12 field_pointer"
              onClick={() =>
                handelModal(
                  <ExpectationSalary
                    section="salary"
                    handelClose={handelClose}
                  />
                )
              }
            >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={salaryImage} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="salaryExpectationRange" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil" />
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetails &&
                    userDetails.salary_from && userDetails.salary_to) > 0
                    ? <p> ₹{userDetails.salary_from}  - ₹{userDetails.salary_to} /months </p>
                    : (<p className="field_placeholder">
                      <FormattedMessage id="salaryExpectationPlaceholder" />
                    </p>)
                }
              </p>
            </div>
          </div>
          {/* salary-expectation-range end */}

           {/* job-type section start */}
		   <div className="row">
            <div className="col-12 field_pointer"
              onClick={() =>
                handelModal(
                  <Userprofileinput
                    section="jobType"
                    handelClose={handelClose}
                  />
                )
              }
            >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BsBriefcase className="user_icon" />
                    <FormattedMessage id="jobType" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil" />
                </div>
              </div>
              {userDetails.job_type >= 0 ? <p className="user_tt">
                {appConstant.JOB_TYPE[userDetails.job_type]}
              </p> : <p className="field_placeholder ml-4 pb-2">
                <FormattedMessage id="jobTypePlaceholder" />
              </p>}   
            </div>
          </div>
          {/* job-type section end */}
          {/* job-timing section start */}
          <div className="row">
            <div className="col-12 field_pointer"
              onClick={() =>
                handelModal(
                  <Userprofileinput
                    section="jobShift"
                    handelClose={handelClose}
                  />
                )
              }
            >
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BiTimeFive className="user_icon" size={20} />
                    <FormattedMessage id="jobShift" />
                  </h6>
                  <BsPencil
                    className="user_icon pencil" />
                </div>
              </div>
              {userDetails.job_timings >= 0 ? 
              <p className="user_tt">
                {appConstant.JOB_SHIFT[userDetails.job_timings]}
              </p> : <p className="field_placeholder ml-4 pb-2">
                <FormattedMessage id="jobTypePlaceholder" />
              </p>}
            </div>
          </div>
          {/* job-timing section end */}

          {/* start video codes */}

          <div className="row">
            <div className="col-12 field_pointer" onClick={() => { history.push("/videoresume") }}>
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={uploadi} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="videoResume" defaultMessage="Video Resume" />
                  </h6>
                  <div>
                    <BsPencil className="user_icon pencil"/>
                  </div>
                </div>
              </div>
              {vedioErr?.length > 0 && (
                <div className="text-danger">{vedioErr}</div>
              )}
              {
                userDetails && userDetails.video_resume
                  ? <>
                    <p className="user_tt video_box">
                      {" "}
                      {userDetails && userDetails.name && userDetails.name.length > 0
                        ? userDetails.name
                        : ""}
                    </p>
                    <button type="button" className="video_btn" onClick={onToggleModal} disabled={userDetails && userDetails.video_resume ? false : true}><FaRegPlayCircle className="btn_icon" /></button>
                  </>
                  : ""
              }
            </div>
            <Player open={modal} toggleModal={onToggleModal} src={userDetails && userDetails.video_resume} />
          </div>

          {/* end video codes */}

        </div>

      </div>
    </>
  );
};
