export const JobActionTypes = {
    GET_JOB: "GET_JOB",
    GET_JOB_PENDING: "GET_JOB_PENDING",
    GET_JOB_FULFILLED: "GET_JOB_FULFILLED",
    GET_USER_LIST_REJECTED: "GET_USER_LIST_REJECTED",

    GET_JOB_LIST: "GET_JOB_LIST",
    GET_JOB_LIST_PENDING: "GET_JOB_LIST_PENDING",
    GET_JOB_LIST_FULFILLED: "GET_JOB_LIST_FULFILLED",

    CREATE_JOB_DATA: "CREATE_JOB_DATA",
    CREATE_JOB_DATA_PENDING: "CREATE_JOB_DATA_PENDING",
    CREATE_JOB_DATA_FULFILLED: "CREATE_JOB_DATA_FULFILLED",

    GET_USER_LIST : "GET_USER_LIST",
    GET_USER_LIST_PENDING : "GET_USER_LIST_PENDING",
    GET_USER_LIST_FULFILLED : "GET_USER_LIST_FULFILLED",

    UPDATE_JOB_DATA: "UPDATE_JOB_DATA",
    UPDATE_JOB_DATA_PENDING: "UPDATE_JOB_DATA_PENDING",
    UPDATE_JOB_DATA_FULFILLED: "UPDATE_JOB_DATA_FULFILLED",

    SCHEDULE_INTERVIEW_DATA: "SCHEDULE_INTERVIEW_DATA",
    SCHEDULE_INTERVIEW_DATA_PENDING: "SCHEDULE_INTERVIEW_DATA_PENDING",
    SCHEDULE_INTERVIEW_DATA_FULFILLED: "SCHEDULE_INTERVIEW_DATA_FULFILLED",

    GET_INTERVIEW_LIST: "GET_INTERVIEW_LIST",
    GET_INTERVIEW_LIST_PENDING: "GET_INTERVIEW_LIST_PENDING",
    GET_INTERVIEW_LIST_FULFILLED: "GET_INTERVIEW_LIST_FULFILLED",

    GET_SHARED_JOB_DETAILS: "GET_SHARED_JOB_DETAILS",
    GET_SHARED_JOB_DETAILS_PENDING: "GET_SHARED_JOB_DETAILS_PENDING",
    GET_SHARED_JOB_DETAILS_FULFILLED: "GET_SHARED_JOB_DETAILS_FULFILLED",

    GET_JOB_DETAILS: "GET_JOB_DETAILS",
    GET_JOB_DETAILS_PENDING: "GET_JOB_DETAILS_PENDING",
    GET_JOB_DETAILS_FULFILLED: "GET_JOB_DETAILS_FULFILLED",
    
    UPDATE_INTERVIEW_LIST: "UPDATE_INTERVIEW_LIST",
    UPDATE_INTERVIEW_LIST_PENDING: "UPDATE_INTERVIEW_LIST_PENDING",
    UPDATE_INTERVIEW_LIST_FULFILLED: "UPDATE_INTERVIEW_LIST_FULFILLED",

    JOB_FILTER_LIST: "JOB_FILTER_LIST",
    JOB_FILTER_LIST_PENDING: "JOB_FILTER_LIST_PENDING",
    JOB_FILTER_LIST_FULFILLED: "JOB_FILTER_LIST_FULFILLED",
    JOB_FILTER_LIST_REJECTED: "JOB_FILTER_LIST_REJECTED",
}
export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}