import "./jobmatch.scss";
import React, { useState, useEffect } from "react";
import { JobCard } from "components/jobseeker/jobmatch/jobcard";
import { JobLists } from "components/jobseeker/jobmatch/joblists";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as JobActions from "redux/actions/JobActions";
import * as UserActions from "redux/actions/UserActions";
import * as JobCategoryActions from "redux/actions/jobcategory";
import storage from "utils/storage";
import { FormattedMessage } from "react-intl";
import JobFilterIcon from "../../../assets/images/job_filter_Icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DistrictList } from "components/jobseeker/DistrictList";
import { useIntl } from "react-intl";
import { common, educationalQualificationList, experienceList, genderList, jobDetails, jobPostTimeList, jobShiftList, jobTypeList } from "config/app.constant";
import { FaTimes } from "react-icons/fa";
import Multiselect from "multiselect-react-dropdown";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";

export const JobView = () => {
	const intl = useIntl();
	const { watch, register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    });
  const history = useHistory();
  const dispatch = useDispatch();
  const sortedDistrictList = DistrictList.sort();
  const [loaded, setLoaded] = useState(false);
  const [isCardView, setisCardView] = React.useState(false);
  const { jobList } = useSelector((state) => state.jobReducer);
  const [jobFilterModal, setJobFilterModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [selectedDistrictList, setSelectedDistrictList] = useState([]);
  const [filterJobs, setFilterJobs] = useState();
  const [userCategory, setUserCategory] = useState([]); // Aray of categories present in user details
  const [allCategories, setAllCategories] = useState([{}]); // All categories from store
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const [skills, setSkills] = useState([''])
  const [selectedSkills, setSelectedSkills] = useState([''])
  const { userDetails } = useSelector((state) => state.userReducer);
  const [message, setMessage] = useState()
  const [salaryTo, setSalaryTo] = useState()
  const [salaryFrom, setSalaryFrom] = useState()
  const userId = storage.get("humlog_user");

  // Handle Open Jobs filter Modal
  const openJobFilter = (userDetails) => {
    setJobFilterModal(true); // close Job filter modal
    categoryList(userDetails.job_intrested); // setting categories of user
    fetchAllCategories(); // Fetch all categories with Id
    fetchUserCategory(); // Fetch user categories with Id
  };
  // Handle close Jobs filter Modal
  const closeJobFilter = () => {
    setJobFilterModal(false);
  };

  // Handle Open search filter Modal
  const openSearch = () => {
    setSearchModal(true)
    setTimeout(() => {
      closeSearch()
    }, 2000);
  };
  // Handle close search filter Modal
  const closeSearch = () => {
    setSearchModal(false)
  };

  // Handle multi selected District Value in Jobs filter Modal
  const onChangeDistrict = (list) => {
    setSelectedDistrictList(list);
  };

  // Handle multi selected Category Value in Jobs filter Modal
  const onChangeCategory = (list) => {
    let categoryId = list.map((item) => {
      return item._id;
    });
    setSelectedCategory(categoryId);
  };

  // Function to compare salaryFrom and salaryTo [ salaryTo must be greater than salaryFrom ]
  const watchSalary_from = watch("salary_from");
  const isGreater = (salary_to) => {
    if (salary_to) {
      if (salary_to > common.LIMIT_50000) {
        return false
      } else {
        return parseInt(salary_to) > parseInt(watchSalary_from);
      }
    } return true
  }

  // Checking Salary Input field validation
  const handleSalaryTo = (salaryTo, salaryFrom) => {
    if (salaryTo?.length && !salaryFrom?.length) {
      return (
        register({
          pattern: {
            value: regexValidation.SALARY_EXPECTATION,
            message: intl.formatMessage({ id: "enterOnlyNumbers" }),
          },
          minLength: {
            value: 4,
            message: intl.formatMessage({ id: "minimumFourDigitAllowed" }),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({ id: "maximumFiveDigit" }),
          },
          validate: {
            validation: (value) =>
              (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
              intl.formatMessage({ id: "salaryMinimum" }),
          },
        })
      )
    } else if (salaryTo?.length && salaryFrom?.length) {
      return (
        register({
          pattern: {
            value: regexValidation.SALARY_EXPECTATION,
            message: intl.formatMessage({ id: "enterOnlyNumbers" }),
          },
          minLength: {
            value: 4,
            message: intl.formatMessage({ id: "minimumFourDigitAllowed" }),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({ id: "maximumFiveDigit" }),
          },
          validate: {
            validation: (value) =>
              (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
              intl.formatMessage({ id: "salaryMinimum" }),
          },
          validate: isGreater,
        })
      )
    } else if (!salaryFrom?.length && !salaryTo?.length) {
      return (
        register()
      )
    }
  }
  const handleSalaryFrom = (salaryFrom) => {
    if (salaryFrom) {
      return (
        register(
          {
            pattern: {
              value: regexValidation.SALARY_EXPECTATION,
              message: intl.formatMessage({ id: "enterOnlyNumbers" }),
            },
            validate: {
              validation: (value) =>
                (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
                intl.formatMessage({ id: "minimumSalary" }),
            },
          }
        )
      )
    }
    else if (!salaryFrom) {
      return (
        register()
      )
    }
  }

  // Handle multi selected Skills Value in Jobs filter Modal
  const onChangeSkills = (list) => {
    setSelectedSkills(list);
  };

  // Handle submission of Jobs filter values
  const submitFilterJobs = (val) => {
    val["district"] = selectedDistrictList; // sending selected districts value
    val["category"] = selectedCategory; // sending selected categories value
    val["skills"] = selectedSkills; // sending selected Skills value
    dispatch(JobActions.getJobFilterListData(val))
      .then((res) => {
        if (res.value.success) {
          setFilterJobs(res.value.data);
          setMessage(res.value.count + ' : ' + res.value.message)
          setSelectedCategory([]); // reset value of category to empty string
          setSelectedDistrictList([]); // reset value of district to empty string
          setSelectedSkills([]); // reset value of skills to empty string
          setSalaryFrom('');
          setSalaryTo('');
          history.push({ path: "/jobs", state: { isFiltered: true } });
          if (res.value.count === 0) {
            openSearch()
          }
        }
        closeJobFilter();
      })
      .catch((err) => console.log(err.message));
  };
  // Function to toggle card view and list view
  const cardview = () => {
    setisCardView((prev) => !prev);
  };

  // Function to fetch user category names detail
  const categoryList = () => {
    const categoryArray = userDetails.job_intrested;
    let categoryNames = categoryArray.map((item) => {
      return item.job_category;
    });
    setUserCategory(categoryNames); // storing user category names
  };

  // Function to fetch all category list
  const fetchAllCategories = () => {
    dispatch(JobCategoryActions.getJobCategoryListData())
      .then((res) => {
        if (res.value.success) {
          setAllCategories(res.value.data);
        }
      })
      .catch((err) => console.log("err", err.message));
  };

  // Function to Fetch users category with _id from List of all categories
  const fetchUserCategory = () => {
    let skills = []
    allCategories.forEach((obj) => {
      if (userCategory.indexOf(obj.name) !== -1) obj["contains"] = true; // separate user categories from List of all categories by adding key ["contains"] = true
    });
    let category;
    let filteredCategory = allCategories.filter((item) => {
      if (item.contains) {
        category = { name: item.name, _id: item._id, skills: item.skills }; // Filter user categories from All categories List
        return category;
      }
    }).map((item) => {
      category = { name: item.name, _id: item._id, skills: item.skills }; // mapping required fields
      skills.push(...item.skills) // Fetching skills of categories of user 
      return category;
    });
    setFilterCategories(filteredCategory); // Storing filtered categories 
    setSkills(skills) // Storing list of skills of user categories
  };
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(JobActions.getJobListData(storage.get("humlog_user")));
      dispatch(UserActions.getUserData(userId));
    }
  }, [loaded, userId, dispatch]);
  return (
    <>
    <div className="joblistview">
      <h5 className="page_title pt-3">
        <FormattedMessage id="viewJobs" defaultMessage="View Jobs" />
      </h5>
      {jobList && jobList.length === 0 ? (
        ""
      ) : (
        <div className="joblistheader">
          <img
            onClick={() => openJobFilter(userDetails)}
            className="job_filter_icon"
            src={JobFilterIcon}
          />
          <button type="button" className="jobviewbtn" onClick={cardview}>
            <span>
              <FormattedMessage id={isCardView ? "list" : "card"} />
            </span>
          </button>
        </div>
      )}
      {isCardView ? (
        <JobCard
          jobList={filterJobs && filterJobs.length ? filterJobs : jobList}
        />
      ) : (
        <JobLists
          jobList={filterJobs && filterJobs.length ? filterJobs : jobList}
        />
      )}
    </div>

    {/*---------- Job filter modal-section start from here ----------*/}
    <Dialog open={jobFilterModal} onClose={closeJobFilter}>
    <DialogTitle><p className="page_title filter_font font-weight-bold "> {common.FILTER_JOBS}</p></DialogTitle>
      <button className="modal_close_btn" onClick={closeJobFilter}>
        <FaTimes />
      </button>
      <DialogContent>
        <DialogContentText>
          <Form
            onSubmit={handleSubmit(submitFilterJobs)}
            className="jobPostForm"
          >
            <div>
              {/* ------ district-section start from here ------ */}
              <Form.Group controlId="district">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="jobLocation"
                    defaultMessage={common.JOB_LOCATION}
                  />
                </Form.Label>
                <Multiselect
                  id="district"
                  options={sortedDistrictList}
                  isObject={false}
                  selectionLimit={3}
                  onSelect={onChangeDistrict}
                  onRemove={onChangeDistrict}
                  placeholder={common.SELECT_DISTRICT}
                />
              </Form.Group>
              {/* ------ district-section end ------ */}

              {/* ------ Salary-section start ------ */}
              <Form.Group controlId="salary">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="jobSalaryRange"
                    defaultMessage={common.SALARY_RANGE}
                  />
                </Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      placeholder={common.EG_7000}
                      name="salary_from"
                      autocomplete="off"
                      className={classNames("form-control", {
                          "is-invalid": errors.salary_from,
                        })}
                        //Set the salary from range by onChange event
                        onChange={(e) => setSalaryFrom(e.target.value)}
                        ref={handleSalaryFrom(salaryFrom)}
                      />
                      {errors.salary_from && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.salary_from.message}
                        </p>
                      )}
                  </Col>
                  <FormattedMessage id="to" defaultMessage="to" />
                  <Col>
                    <Form.Control
                      placeholder={common.EG_50000}
                      name="salary_to"
                      autocomplete="off"
                      className={classNames("form-control", {
                          "is-invalid": errors.salary_to,
                        })}
                        //Set the salary to range by onChange event
                        onChange={(e) => setSalaryTo(e.target.value)}
                        ref={handleSalaryTo(salaryTo, salaryFrom)}
                      />
                      {/* if user put minimum salary in max-salary range compare to minimum salary then it produce an error */}
                      {errors.salary_to &&
                        errors.salary_to.type === "validate" && (
                          <p className="text-danger p-0 m-0">
                            <FormattedMessage id="salaryMinimum" />
                          </p>
                        )}
                      {errors.salary_to && (
                        <p className="text-danger p-0 m-0">
                          {errors.salary_to.message}
                        </p>
                      )}
                  </Col>
                </Form.Row>
              </Form.Group>
              {/* ------ Salary-section end ------ */}

              {/* ------ Educational Qualification section start ------ */}
              <Form.Group controlId="educational_qualification">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="educationalQualification"
                    defaultMessage={jobDetails.EDU_QUALIFICATION}
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="educational_qualification"
                  ref={register()}
                >
                  {educationalQualificationList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Educational Qualification  section end ------ */}

              {/* ------ Gender preference section start ------ */}
              <Form.Group controlId="gender">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="gender"
                    defaultMessage={common.GENDER}
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="gender"
                  ref={register()}
                >
                  {genderList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Gender preference section end ------ */}

              {/* ------ Work Experience section start ------ */}
              <Form.Group controlId="experience">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="experienceRequired"
                    defaultMessage={common.EXP_REQUIRED}
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="experience"
                  ref={register()}
                >
                  {experienceList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Work Experience section end ------ */}

              {/* ------ Job-category section start ------ */}
              <Form.Group controlId="job-category">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="jobCategory"
                    defaultMessage={common.JOB_CATEGORY}
                  />
                </Form.Label>
                <Multiselect
                  id="jobCategory"
                  options={filterCategories}
                  isObject={true}
                  selectionLimit={3}
                  displayValue="name"
                  onSelect={onChangeCategory}
                  onRemove={onChangeCategory}
                  placeholder={common.SELECT_CATEGORY}
                />
              </Form.Group>
              {/* ------ Job-category section end ------ */}

              {/* ------ Skills section start ------ */}
              <Form.Group controlId="skills">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage id="skills" defaultMessage={common.SKILLS} />
                </Form.Label>
                <Multiselect
                  id="skills"
                  options={skills}
                  isObject={false}
                  selectionLimit={3}
                  onSelect={onChangeSkills}
                  onRemove={onChangeSkills}
                  placeholder={common.SELECT_SKILLS}
                />
              </Form.Group>
              {/* ------ Skills section end ------ */}

              {/* ------ Job Type section start ------ */}
              <Form.Group controlId="job_type">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage id="jobType" defaultMessage={common.JOB_TYPE} />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="job_type"
                  ref={register()}
                >
                  {jobTypeList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Job Type section end ------ */}

              {/* ------ Job Shift section start ------ */}
              <Form.Group controlId="job_timings">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="jobShift"
                    defaultMessage={common.JOB_SHIFT}
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="job_timings"
                  ref={register()}
                >
                  {jobShiftList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Job Shift section end ------ */}

              {/* ------ Job Posted Time section start ------ */}
              <Form.Group controlId="job_posted_time">
                <Form.Label className="d-flex justify-content-start job_filter_title">
                  <FormattedMessage
                    id="jobPostTime"
                    defaultMessage={common.JOB_POST_TIME}
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  autocomplete="off"
                  name="job_posted_time"
                  ref={register()}
                >
                  {jobPostTimeList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* ------ Job Posted Time end ------ */}

              <div className="text-center">
			  <Button className="apply-filter-btn" type="submit">{common.APPLY_FILTERS}</Button>
              </div>
            </div>
          </Form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
    {/* ---------- Job filter modal end ----------*/}


    {/*---------- Search filter modal-section start from here ----------*/}
    <Dialog open={searchModal} onClose={closeSearch}>
      <DialogTitle className="page_title">{message}</DialogTitle>
      <button className="modal_close_btn" onClick={closeSearch}>
        <FaTimes />
      </button>
      {/* <DialogContent>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
      </DialogContent> */}
    </Dialog>
    {/* ---------- Search filter modal-section modal end ----------*/}
  </>
);
};