import "../../../style/common/userprofile.scss";
import React, { useState, useEffect } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory, useLocation } from "react-router-dom";
import { FaRegPlayCircle } from "react-icons/fa";
import { BsBriefcase } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import * as jobActions from "redux/actions/JobActions";
import useri from "assets/images/useri.png";
import booki from "assets/images/booki.png";
import genderi from "assets/images/genderi.png";
import jobi from "assets/images/jobsi.png";
import birthi from "assets/images/birthi.png";
import locationi from "assets/images/locationi.png";
import uploadi from "assets/images/uploadi.png";
import pencili from "assets/images/pencili.png";
import language from "assets/images/language.png";
import calli from "../../../assets/images/calli.png";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "components/shared/videoplayer/player";
import avatar from "../../../assets/images/male_avatar.png";
import { BiCertification } from "react-icons/bi";
import { FiPhoneCall, FiEye } from "react-icons/fi";
import storage from "utils/storage";
import { IoIosCloseCircle } from "react-icons/io";
import { BsCheck } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import backbtn from "../../../assets/images/backbtn.svg";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);


const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const Userprofile = (props) => {
  const [title, settitle] = useState("shubham");
  const [picture, setPicture] = useState(null);

  const onChangePicture = (e) => {
    setPicture(URL.createObjectURL(e.target.files[0]));
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState(false);
  let query = useQuery();
  let userId = query.get("id");
  let jobId = query.get("job_id");
  const [shortistModal, setShortistModal] = useState(false); // showing & hiding shortlist or decide later modal

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      let query = {
        _id: userId,
      };
      if(jobId.length) {
        query.job_id = jobId
      }
      dispatch(userActions.getUserDataBySarthi(query));
    }
  }, [loaded, dispatch, userId]);
  const { userDetailsById } = useSelector((state) => state.userReducer);
  const [profilePercentage, setProfilePercentage] = useState(0);

  const [choice, setchoice] = useState("");

  const [showModal, setshowModal] = useState(false);
  const [ashowModal, asetshowModal] = useState(false);
  const [showUserNumber, setShowUserNumber] = useState(false);

  const update = (e) => {
    console.log(e.target.value);
  };

  const handelLogout = () => {
    dispatch(authActions.logout);
    setTimeout(() => {
      window.location.reload();
      history.push("/JobSeekerlogin");
    }, 200);

    
  };
  const onToggleModal = () => {
    setModal(!modal);
  };

  const showNumber = () => {
    setShowUserNumber(true);
  }
  /**
   * @description method to post the click count when employer try to see/call the jobseeker
   * @param {string} userId user id of particular jobseeker 
   */
  const viewJobCount = (userId) => {
    try {
      let data = {
        job_id: jobId,
        user_id: userId
      };
      dispatch(userActions.postJobViewCount(data));
    } catch (err) {
      console.log("error in posting click counts on call button", err);
    }
  }
  /**
   * @Description function to update users job status
   * @param {String} status status to be updated
   * @param {Object} job User's applied Job details
   * @param {String} userId User's Object id
   **/
  const changeUserJobStatus = (status, job, userId) => {
    let jobData = job;
    jobData.status = status;
    let data = { _id: userId, jobs: jobData };
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          console.log(res.value.message);
          dispatch(jobActions.getUserListByJobId(job.job_id));
        }
        history.goBack()
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="main_user st">
        <h5 className="page_title">
          <FormattedMessage id="userProfile" defaultMessage="User Profile" />
        </h5>
        <div className="row p-0 m-0 no-gutters">
          <div className="col-12">
            <div className="d-flex justify-content-center ">
              <button type="button" className="back_btn" onClick={() => {
                ((props && props.location && props.location.status === "Applied") ||
                  (props && props.location && props.location.status === "Rejected")) ?
                  setShortistModal(true) : history.goBack()
              }}>
                <img src={backbtn} alt="back button" className="img-fluid" />
              </button>
              <div>
                <div className="img_style_prev main_pic_box imgcls">
                  {userDetailsById.profile_pic &&
                  userDetailsById.profile_pic.length > 0 ?(
                    <Image picture={`${userDetailsById.profile_pic}?${Date.now()}`} alt="Profile Picture"></Image>
                  ) : (
                    <Image picture={avatar} alt="Profile Picture"></Image>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-1 text-capitalize d-flex justify-content-center font-weight-bold">
              {" "}
              {userDetailsById && userDetailsById.name && userDetailsById.name.length > 0
                ? userDetailsById.name
                : ""}
              <sup></sup>
            </div>
            <div className="user_tt user-location-value d-flex align-items-center justify-content-center">
              <p>
                 {userDetailsById &&
                userDetailsById.address_home &&
                userDetailsById.address_home.length > 0
                  ? userDetailsById.address_home
                  : ""}
              </p>
            </div>
          </div>
          {/* start of Shortlist & not Shortlist Buttoos code  */}
          {/* If status is Applied */}
          {(props && props.location && props.location.status === "Applied") ?
            (<div className="col-12 mt-2 d-flex justify-content-around">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="notShortlist" defaultMessage="Not Shortlist" />
                </span>
              </button>
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Shortlisted",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="shortlist" defaultMessage="Shortlist" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* If status is rejected */}
          {(props && props.location && props.location.status === "Rejected") ?
            (<div className="col-12 mt-2 d-flex justify-content-center">
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Shortlisted",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="shortlistAgain" defaultMessage="Shortlist Again" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* If status is Shortlisted */}
          {(props && props.location && props.location.status === "Shortlisted") ?
            (<div className="col-12 mt-2 d-flex justify-content-center">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="notShortlist" defaultMessage="Not Shortlist" />
                </span>
              </button>
            </div>)
            : ('')}
            {/* If status is Applied */}
          {(props && props.location && props.location.status === "Selected") ?
            (<div className="col-12 mt-2 d-flex justify-content-around">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="reject" defaultMessage="Reject" />
                </span>
              </button>
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Hired",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="hired" defaultMessage="Hired" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* End of Shortlist & not Shortlist Buttoos code  */}
        </div>

        <div className="container">
        
          {/* start about section */}
          <div className="about_me_box">
            <div className="row">
              <div className="col-12">
                <div className="profile_field_box">
                  <div className="label_box">
                    <h6 className="user_one">
                      <img src={useri} className="user_icon" width="16px" height="16px" />
                      <FormattedMessage id="aboutMe" defaultMessage="About me" />
                    </h6>
                  </div>
                </div>
                <div>
                  <p className="">
                    {userDetailsById &&
                      userDetailsById.about_me &&
                      userDetailsById.about_me.length > 0 ? (
                      <FormattedMessage id="iam" defaultMessage="I am.." />
                    ) : (
                      ""
                    )}
                    {userDetailsById &&
                      userDetailsById.about_me &&
                      userDetailsById.about_me.length > 0
                      ? userDetailsById.about_me.map((data) => data.label).join(", ")
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* end about section */}

          {/* contact number box start */}
          {props.history.location.status === 'Shortlisted' && userDetailsById.contact ? (
            <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={calli} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="mobileNumber" defaultMessage="Mobile number" />
                  </h6>
                </div>
              </div>
              <div>
                <div className="user_tt">
                  {
                    (
                      userDetailsById &&
                      userDetailsById.contact &&
                      userDetailsById.contact.length) > 0 && 
                      showUserNumber ? 
                      userDetailsById.contact : 
                      "xxx-xxx-xxxx"
                  }
                  <p className="showNoBtn" onClick={showNumber}>
                  {
                    !showUserNumber ? 
                    (
                      <span onClick={() => 
                          {
                            viewJobCount(userDetailsById._id);
                          }
                        }>
                        <FiEye />
                      </span>
                    ) : (
                      <a href={`tel:${userDetailsById.contact}`}>
                        <FiPhoneCall />
                      </a>
                    )
                  }
                  </p>
                </div>
              </div>
            </div>
          </div>
          ) : ("")}
          {/* contact number box end */}

          {/* start job categeory page */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={jobi} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="jobInterest" defaultMessage="Job Interested In" />
                  </h6>
                </div>
              </div>
              <div className="ml-4 pb-2">
                {userDetailsById &&
                  userDetailsById.job_intrested &&
                  userDetailsById.job_intrested.length > 0 &&
                  userDetailsById.job_intrested.map((val) => {
                    return (
                      <>
                      <div className="user_btna mr-2" style={{ display: "inline-block" }}>{val.job_category}</div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* end job categeory */}

          {/* start experience */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BsBriefcase className="user_icon" />
                    <FormattedMessage id="yearsOfExp" defaultMessage="Years of experience" />
                  </h6>
                </div>

                <p className="user_tt">
                  {
                    userDetailsById && userDetailsById.experience === 0
                      ? "Fresher"
                      : userDetailsById && userDetailsById.experience === 1
                        ? "Less than 1 Year"
                        : userDetailsById && userDetailsById.experience === 2
                          ? "1-2 years"
                          : userDetailsById && userDetailsById.experience === 3
                            ? "2-4 years"
                            : userDetailsById && userDetailsById.experience === 4
                              ? "4-6 years"
                              : userDetailsById && userDetailsById.experience === 5
                                ? "6-8 years"
                                : userDetailsById && userDetailsById.experience === 6
                                  ? "8-10 years"
                                  : userDetailsById && userDetailsById.experience === 7
                                    ? "10+ years"
                                    : ""
                  }
                </p>
              </div>

            </div>
          </div>

          {/* end experience */}

          

          {/* start skills codes */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={pencili} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="skills" defaultMessage="Skills" />
                  </h6>
                </div>
              </div>
              <div className="ml-4 pb-2">
                <p>
                  {userDetailsById &&
                    userDetailsById.job_intrested &&
                    userDetailsById.job_intrested.length > 0 &&
                    userDetailsById.job_intrested.map((val) => {
                      return (
                        <>
                          <div className="">{val.skills.map((skill) => {
                            return (
                              <>
                                <div className="user_btna mr-2" style={{ display: "inline-block" }}>{skill}</div>
                              </>
                            )
                          })}</div>
                        </>
                      );
                    })}
                </p>
              </div>
            </div>
          </div>

          {/* end skills codes */}

          {/* education codes start */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one ml-1 mr-2">
                    <img src={booki} width="16px" height="20px" className="user_icon" />
                    <FormattedMessage id="educationQualification" defaultMessage="Education & Qualification" />
                  </h6>
                </div>
              </div>
              <div>
                <p className="user_tt">
                {
                  userDetailsById && parseInt(userDetailsById.qualification) === 0
                    ? "below 10"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 1
                    ? "10 th pass"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 2
                    ? "12 th pass"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 3
                    ? "B.A"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 4
                    ? "B.Sc"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 5
                    ? "B.Com"
                    : userDetailsById && parseInt(userDetailsById.qualification) === 6
                    ? "Other"
                    : userDetailsById.qualification}
                </p>
              </div>
            </div>
          </div>

          {/* end  education codes  */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <BiCertification className="user_icon" />
                    <FormattedMessage id="technicalCertification" defaultMessage="Technical Certification"/>
                  </h6>
                </div>
              </div>
              <p className="">
                {
                  userDetailsById && userDetailsById.technical_cert
                  ? userDetailsById.technical_cert.map((value, index) => {
                      return (
                        <p className="user_tt">
                          {value.cert} {value.branch ? <>( {value.branch} )</> : <></>}
                        </p>
                      );
                    })
                  : (" ")
                }
              </p>
            </div>
          </div>
          
          

          {/* gender section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={genderi} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="gender" defaultMessage="Gender" />
                  </h6>
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {userDetailsById && userDetailsById.gender === 0 ? (
                    <FormattedMessage id="female" defaultMessage="Female" />
                  ) : userDetailsById && userDetailsById.gender === 1 ? (
                    <FormattedMessage id="male" defaultMessage="Male" />
                  ) : userDetailsById && userDetailsById.gender === 2 ? (
                    <FormattedMessage id="others" defaultMessage="Others" />
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* DOB section starts */}
          
          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={birthi} className=" user_icon" />
                    <FormattedMessage id="dob" defaultMessage="Date of Birth" />
                  </h6>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetailsById && userDetailsById.dob && userDetailsById.dob.length) > 0
                  ? new Date(userDetailsById.dob).toLocaleDateString()
                  : ""
                }
                
              </p>
            </div>
          </div>

          {/* Language known section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={language} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="languageIKnow" defaultMessage="Languages I Know" />
                  </h6>
                </div>
              </div>
              <div className="ml-4 pb-2">
                {userDetailsById &&
                  userDetailsById.language_known &&
                  userDetailsById.language_known.length > 0 &&
                  userDetailsById.language_known.map((val) => {
                    return (
                      <>
                        <div className="user_btna mr-2" style={{ display: "inline-block" }}>{val}</div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Speak English section starts */}
          
          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={language} className="user_icon" width="16px" height="16px" />
                    <FormattedMessage id="speakEnglish" defaultMessage="Speak English" />
                  </h6>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetailsById &&
                  userDetailsById.speak_english &&
                  userDetailsById.speak_english.length) > 0
                  ? userDetailsById.speak_english
                  : ""
                }
              </p>
            </div>
          </div>

          {/* current district section starts */}
          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={locationi} className=" user_icon" width="16px" height="16px" />
                    <FormattedMessage id="currentDist" defaultMessage="Current District" />
                  </h6>
                </div>
              </div>
              <p className="user_tt">
                {
                  (userDetailsById &&
                  userDetailsById.address_current &&
                  userDetailsById.address_current.length) > 0
                  ? userDetailsById.address_current
                  : ""
                }
              </p>
            </div>
          </div>

          {/* home district section starts */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={locationi} className=" user_icon" width="16px" height="16px" />
                    <FormattedMessage id="homeDistrict" defaultMessage="Home District" />
                  </h6>
                </div>
              </div>
              <div>
                <p className="user_tt">
                  {
                    (userDetailsById &&
                    userDetailsById.address_home &&
                    userDetailsById.address_home.length) > 0
                    ? userDetailsById.address_home
                    : null
                  }
                </p>
              </div>
            </div>
          </div>

          {/* start video codes */}

          <div className="row">
            <div className="col-12">
              <div className="profile_field_box">
                <div className="label_box">
                  <h6 className="user_one">
                    <img src={uploadi} className="user_icon" width="16px" height="16px"/>
                    <FormattedMessage id="videoResume" defaultMessage="Video Resume" />
                  </h6>
                </div>
                {
                  userDetailsById && userDetailsById.video_resume
                  ? <>
                      <p className="user_tt video_box">
                        {" "}
                        {userDetailsById && userDetailsById.name && userDetailsById.name.length > 0
                      ? userDetailsById.name
                      : ""}
                      </p>
                      <button type="button" className="video_btn" onClick={onToggleModal} disabled={userDetailsById && userDetailsById.video_resume ? false : true}><FaRegPlayCircle className="btn_icon"/></button>
                    </>
                  : ""
                }
            </div>
            <Player open={modal} toggleModal={onToggleModal} src={userDetailsById && userDetailsById.video_resume}/>
          </div>
        </div>
        {/* end video codes */}  
          {/*---------- start of Shortlist & not Shortlist Buttoos code ----------*/}
          {/* If status is Applied */}
          {(props && props.location && props.location.status === "Applied") ?
            (<div className="col-12 mt-2 d-flex justify-content-around">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="notShortlist" defaultMessage="Not Shortlist" />
                </span>
              </button>
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Shortlisted",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="shortlist" defaultMessage="Shortlist" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* If status is rejected */}
          {(props && props.location && props.location.status === "Rejected") ?
            (<div className="col-12 mt-2 d-flex justify-content-center">
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Shortlisted",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="shortlistAgain" defaultMessage="Shortlist Again" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* If status is Shortlisted */}
          {(props && props.location && props.location.status === "Shortlisted") ?
            (<div className="col-12 mt-2 d-flex justify-content-center">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="notShortlist" defaultMessage="Not Shortlist" />
                </span>
              </button>
            </div>)
            : ('')}
            {/* If status is Applied */}
          {(props && props.location && props.location.status === "Selected") ?
            (<div className="col-12 mt-2 d-flex justify-content-around">
              <button className="btn_va p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Rejected",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="reject" defaultMessage="Reject" />
                </span>
              </button>
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Hired",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="hired" defaultMessage="Hired" />
                </span>
              </button>
            </div>)
            : ('')}
          {/* ---------- End of Shortlist & not Shortlist Buttoos code ----------*/}
      </div>
    </div>

      {/*---------- Shortlist now or decide later modal-section start from here ----------*/}
      <Dialog
        open={shortistModal}
        onClose={() => setShortistModal(false)}
        
      >
        <DialogTitle className="shortlist_modal">
        <FormattedMessage id="shortlistDiscussion" defaultMessage="Do you want to shortlist the applicant?" />
        </DialogTitle>
        <button className="modal_close_btn" onClick={() => setShortistModal(false)}>
          <FaTimes />
        </button>
        <DialogContent>
          <DialogContentText>
          <div className="col-12 mt-2 d-flex justify-content-around">
              <button className="btn_va p-1"
                onClick={() =>
                  history.goBack()
                }
              >
                <IoIosCloseCircle className="cross_icon" />
                <span className="icon_text">
                  <FormattedMessage id="decideLater" defaultMessage="Decide Later" />
                </span>
              </button>
              <button className="btn_vc p-1"
                onClick={() =>
                  changeUserJobStatus(
                    "Shortlisted",
                    props.location.job,
                    userDetailsById._id,
                  )
                }
              >
                <BsCheck className="box_ib job_pb " />
                <span className="icon_text">
                  <FormattedMessage id="yes" defaultMessage="Yes" />
                </span>
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
{/* ---------- Shortlist now or decide later modal end ----------*/}
    </>
  );
};
